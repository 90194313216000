import './App.css';
import Html5QrcodeScannerPlugin from "./components/Html5QrcodeScannerPlugin";
import React from "react";


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            log: [],
        }
    }


    test = (code) => {
        console.log(code);
        this.setState({
            log: [...this.state.log, code]
        })
    }

    render() {
        return (
            <div className="App">
                <Html5QrcodeScannerPlugin
                    fps={10}
                    qrbox={window.innerWidth/2}
                    disableFlip={false}
                    qrCodeSuccessCallback={this.test}
                    qrCodeErrorCallback={console.error}/>

                <div className={"log"}>
                    <pre>
                        {this.state.log.join('\n')}
                    </pre>
                </div>
            </div>
        );
    }
}

export default App;
